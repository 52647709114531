<template>
  <div>

    <my-team
      v-if="myTeam.id"
      :team="myTeam"
    />

    <dispatcher-statistics v-if="hasAtLeastOneRole(me.roles, ['ROLE_DISPATCHER'])" />

    <team-statistics
      v-if="hasAtLeastOneRole(me.roles, ['ROLE_DISPATCHER'])"
      class="mb-2"
    />

    <my-schedule v-if="isUserSchedulable" />

  </div>
</template>

<script>
import MyTeam from '@/views/dashboard/components/MyTeam.vue'
import DispatcherStatistics from '@/views/dashboard/components/DispatcherStatistics.vue'
import TeamStatistics from '@/views/dashboard/components/TeamStatistics.vue'
import guard from '@/guard'
import MySchedule from '@/views/dashboard/Schedule/MySchedule/MySchedule.vue'

export default {
  components: {
    MySchedule,
    MyTeam,
    DispatcherStatistics,
    TeamStatistics,
  },
  computed: {
    me() {
      return this.$store.state.auth.activeUser
    },
    myTeam() {
      return this.$store.state.auth.userTeam
    },
    isUserSchedulable() {
      const myRoles = this.$store.getters['auth/getUserRawRoles']
      const rolesForScheduling = [
        ...this.$store.getters['appConfig/config'].schedule.userRolesForScheduling,
        ...['ROLE_DRIVER', 'ROLE_PARAMEDIC'],
      ]

      return rolesForScheduling.some(role => myRoles.includes(role))
    },
  },
  methods: {
    hasAtLeastOneRole: guard.hasAtLeastOneRole,
  },
}
</script>
