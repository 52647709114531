import { capitalizeFirstLetter, cloneNested, transformDateToHumanReadablePretty } from '@/helpers/helpers'
import { computed, ref } from '@vue/composition-api'
import dayjs from 'dayjs'
import useAppConfig from '@core/app-config/useAppConfig'
import store from '@/store/store'
import { $themeBreakpoints } from '@themeConfig'

export default () => {
  const { skin } = useAppConfig()
  const isDark = computed(() => skin.value === 'dark')
  const isMobileWidth = computed(() => $themeBreakpoints.lg > store.state.app.windowWidth)
  const me = computed(() => store.getters['auth/getUser'])
  const mySchedule = computed(() => {
    const days = {}
    store.getters['schedules/mySchedules'].forEach(schedule => {
      const dateFormatted = dayjs(schedule.timeFrom).format('YYYY-MM-DD')
      if (!days[dateFormatted]) {
        days[dateFormatted] = []
      }
      days[dateFormatted].push(schedule)
    })

    return days
  })
  const myShiftsCount = computed(() => {
    let count = 0
    Object.values(mySchedule.value).forEach(schedules => {
      schedules.forEach(() => {
        count += 1
      })
    })
    return count
  })
  const myWorkingHours = computed(() => {
    let hours = 0
    Object.values(mySchedule.value).forEach(schedules => {
      schedules.forEach(schedule => {
        hours += dayjs(schedule.timeTo).diff(dayjs(schedule.timeFrom), 'hours', true)
      })
    })
    return hours
  })
  const mySalary = computed(() => {
    const hourlySalaryGross = parseFloat(me.value.hourlySalaryGross)
    const hourlySalaryForReadinessGross = parseFloat(me.value.hourlySalaryForReadinessGross)
    if (!hourlySalaryGross && !hourlySalaryForReadinessGross) {
      return null
    }

    if (hourlySalaryForReadinessGross) {
      return myWorkingHours.value * hourlySalaryForReadinessGross.toFixed(2)
    }

    return myWorkingHours.value * hourlySalaryGross.toFixed(2)
  })
  const hideSalary = ref(true)

  const shiftDefinitions = computed(() => store.getters['appConfig/config'].schedule.shiftDefinitions)
  const currentMonth = ref(dayjs().startOf('month'))
  const monthArray = ref({})
  const periodStart = computed(() => currentMonth.value.startOf('week'))
  const periodEnd = computed(() => currentMonth.value.add(1, 'month').endOf('week'))

  const dayjsWeekdays = computed(() => {
    const weekdays = cloneNested(dayjs.localeData().weekdays())
    if (dayjs.localeData().firstDayOfWeek() === 1) {
      weekdays.push(weekdays.shift())
    }
    return weekdays
  })
  const loading = ref(false)

  const init = () => {
    let date = periodStart.value.clone()
    let week = {}
    const month = []

    while (date < periodEnd.value) {
      week = {
        number: date.week(),
        days: [],
      }
      for (let i = 0; i < 7; i += 1) {
        let schedules = []
        if (mySchedule.value[date.format('YYYY-MM-DD')]) {
          schedules = mySchedule.value[date.format('YYYY-MM-DD')]
        }
        week.days.push({
          date,
          weekday: date.weekday(),
          formatted: date.format('YYYY-MM-DD'),
          isToday: date.isToday(),
          isCurrentMonth: currentMonth.value.format('YYYYMM') === date.format('YYYYMM'),
          isPast: date.isBefore(dayjs(), 'day'),
          schedules,
        })

        date = date.add(1, 'day')
      }
      month.push(week)
      week = []
    }

    monthArray.value = month
  }
  init()

  const fetchMySchedule = () => {
    loading.value = true
    store.dispatch('schedules/fetchMyScheduleForMonth', currentMonth.value)
      .then(() => {
        init()
      })
      .catch(err => {
        throw err
      })
      .finally(() => {
        loading.value = false
      })
  }
  fetchMySchedule(currentMonth)

  const nextMonth = () => {
    currentMonth.value = currentMonth.value.add(1, 'month')
    fetchMySchedule(currentMonth)
  }

  const prevMonth = () => {
    currentMonth.value = currentMonth.value.subtract(1, 'month')
    fetchMySchedule(currentMonth)
  }

  return {
    isDark,
    isMobileWidth,
    shiftDefinitions,

    currentMonth,
    dayjsWeekdays,
    monthArray,
    me,
    mySchedule,
    myShiftsCount,
    myWorkingHours,
    mySalary,
    hideSalary,

    loading,

    nextMonth,
    prevMonth,

    dayjs,
    capitalizeFirstLetter,
    transformDateToHumanReadablePretty,
  }
}
