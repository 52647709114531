<template>
  <div>
    <b-row>
      <b-col class="mb-50">
        <h4>
          {{ $t('dashboard.my-schedule.title') }}
        </h4>
      </b-col>
    </b-row>
    <b-row class="mb-1">
      <b-col>
        <h2 class="mt-25">
          {{ capitalizeFirstLetter(currentMonth.format('MMMM YYYY')) }}
        </h2>
      </b-col>
      <b-col class="text-right">
        <b-btn-group>
          <b-button
            variant="secondary"
            :disabled="currentMonth.add(1, 'month').isBefore(dayjs())"
            @click="prevMonth"
          >
            <feather-icon icon="ChevronLeftIcon" />
          </b-button>
          <b-button
            variant="secondary"
            :disabled="currentMonth.subtract(0, 'months').isAfter(dayjs())"
            @click="nextMonth"
          >
            <feather-icon icon="ChevronRightIcon" />
          </b-button>
        </b-btn-group>
      </b-col>
    </b-row>

    <b-card
      v-if="currentMonth.subtract(1, 'months').add(24, 'days').isAfter(dayjs()) "
      class="text-center font-medium-3"
    >
      {{ `Grafik na kolejny miesiąc zostanie udostępniony ${dayjs().startOf('month').add(24, 'days').format('DD MMMM YYYY')}` }} r.
    </b-card>

    <div v-else>
      <b-row>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >

          <rc-overlay
            :show="loading"
            rounded
          >
            <statistic-card-horizontal
              icon="CalendarIcon"
              :statistic="myShiftsCount"
              :statistic-title="$t('dashboard.my-schedule.number-of-shifts-in-month')"
              color="secondary"
            />
          </rc-overlay>

        </b-col>

        <b-col
          cols="12"
          md="6"
          lg="4"
        >

          <rc-overlay
            :show="loading"
            rounded
          >
            <statistic-card-horizontal
              icon="ClockIcon"
              :statistic="myWorkingHours"
              :statistic-title="$t('dashboard.my-schedule.working-hours-in-month')"
              color="secondary"
            />
          </rc-overlay>

        </b-col>

        <b-col
          v-if="mySalary !== null"
          cols="12"
          md="6"
          lg="4"
          @click="hideSalary = !hideSalary"
        >

          <rc-overlay
            :show="loading"
            rounded
          >
            <statistic-card-horizontal
              icon="CreditCardIcon"
              :statistic="hideSalary ? $t('shared.hidden') : `${mySalary} ${$t('shared.currency-symbol')}`"
              :statistic-title="parseFloat(me.hourlySalaryForReadinessGross) ? $t('dashboard.my-schedule.min-salary') : $t('dashboard.my-schedule.salary')"
              color="success"
              class="cursor-pointer"
            />
          </rc-overlay>

        </b-col>

      </b-row>

      <b-row>
        <b-col>
          <rc-overlay
            :show="loading"
            rounded
          >
            <b-card>
              <div
                class="overflow-auto"
              >
                <table class="schedule-calendar-table w-100">
                  <thead>
                    <tr>
                      <th
                        v-for="(day, weekdayKey) in dayjsWeekdays"
                        :key="`weekday-key-${weekdayKey}`"
                        class="d-none d-lg-table-cell"
                      >
                        <div class="m-50">
                          <span class="font-weight-bold text-uppercase font-medium-3 mb-50 d-block">{{ day }}</span>
                        </div>
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr
                      v-for="(week, weekKey) in monthArray"
                      :key="`week-key-${weekKey}`"
                      class="d-block d-lg-table-row"
                    >
                      <td
                        v-for="(day, dayKey) in week.days"
                        :key="`day-key-${dayKey}`"
                        :style="{ 'width': !isMobileWidth ? '13%' : 'inherit' }"
                        :class="{'d-block': isMobileWidth && day.schedules.length, 'd-lg-table-cell': !isMobileWidth, 'align-text-top': true, 'd-none': isMobileWidth && !day.schedules.length}"
                      >
                        <div :class="{'d-none': !day.isCurrentMonth && isMobileWidth}">
                          <div
                            :class="{'text-muted': !day.isCurrentMonth, 'font-weight-bold text-uppercase font-medium-3 d-inline-block': true}"
                          >
                            <span class="d-lg-none">
                              {{ capitalizeFirstLetter(day.date.format('dddd')) }},
                              {{ transformDateToHumanReadablePretty(day.date.format()) }}
                            </span>
                            <b-avatar
                              :class="{'d-none d-lg-block font-medium-3 cursor-pointer': true, 'text-muted': !day.isCurrentMonth }"
                              :variant="day.isCurrentMonth ? 'light-secondary' : 'none'"
                              size="36"
                            >
                              {{ day.date.format('D') }}
                            </b-avatar>
                          </div>

                          <div
                            v-show="day.isCurrentMonth"
                            class="m-50"
                          >
                            <div
                              v-for="schedule in day.schedules"
                              :key="`schedule-key-${schedule.id}`"
                              class="p-1 rounded text-secondary mb-25"
                              :class="{'bg-light-secondary': day.isPast, 'bg-light-success': !day.isPast}"
                            >
                              <h4>
                                {{ $t('schedule.shift') }}: {{ schedule.name }}
                                <span class="text-nowrap text-muted font-small-4">
                                  <br> {{ dayjs(schedule.timeFrom).format('HH:mm') }} - {{ dayjs(schedule.timeTo).format('HH:mm') }}
                                </span>
                              </h4>
                              <h5 class="mb-0">
                                {{ $t('roles.' + schedule.asRole) }}
                                <span v-if="!!schedule.teamName">
                                  <br>{{ $t('team.title') }}: {{ schedule.teamName.name }}
                                </span>
                              </h5>
                              <div />
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </b-card>
          </rc-overlay>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import setup from '@/views/dashboard/Schedule/MySchedule/setup'
import { BCol, BRow } from 'bootstrap-vue'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import RcOverlay from '@/layouts/components/rc/RcOverlay.vue'

export default {
  components: {
    RcOverlay, StatisticCardHorizontal, BCol, BRow,
  },
  setup,
}
</script>

<style scoped lang="scss">

</style>
